import { Store, Http, IHttpAxiosInstance } from '@gf-tech/utils';

const store = new Store();

const http: IHttpAxiosInstance = new Http().create({
    retry: false,
});

// http.interceptors.request.use(config => {
//     if (
//         config.url.startsWith('/msx-nurse') &&
//         config.params?.currentPage &&
//         config.params?.pageSize
//     ) {
//         config.params.current = config.params.currentPage;
//         config.params.size = config.params.pageSize;
//         delete config.params.currentPage;
//         delete config.params.pageSize;
//     }
//     return config;
// });

// http.interceptors.response.use((response: any) => {
//     if (response && response.size && response.current) {
//         response.currentPage = response.current;
//         response.pageSize = response.size;
//         delete response.current;
//         delete response.size;
//     }
//     return response;
// });

export { http, store };
